import React from "react";

const formatBigValue = (value) => {
  const suffixes = ["", "k", "M", "B", "T"];

  if (value === 0) return "0";

  const tier = Math.floor(Math.log10(Math.abs(value)) / 3);

  if (tier === 0) return value.toFixed(2); // Display two decimal places for values less than 1000

  const suffix = suffixes[tier];
  const scale = Math.pow(10, tier * 3);
  const scaled = value / scale;

  return scaled.toFixed(2) + suffix;
};

const BigValueFormatterVolume = ({ value }) => {
  if (isNaN(value) || value === undefined || value === null || value === "") {
    return <span className="text-xs text-n-40">N/A</span>;
  }
  const formattedValue = formatBigValue(value);

  return (
    <span>
      <strong className="">${formattedValue}</strong> - 24h Volume
    </span>
  );
};

export default BigValueFormatterVolume;
