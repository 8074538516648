import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header/Header";
import DogemobHeaderImage from "../assets/images/banner-mobil.png";
import DogemobTokenomics from "../assets/images/fade1.png";
import DogemobThumb from "../assets/images/fade3.png";
import DogemobThink from "../assets/images/dogemobth.png";
import DogemobRambo from "../assets/images/website banners-02.png";
import TreeRight from "../assets/images/dog-head_2.png";
import TreeLeft from "../assets/images/dog_head.png";
import DogemobHi from "../assets/images/dogemobhi.png";
import DogemobGov from "../assets/images/fade2.png";
import CyberPunkAsset1 from "../assets/images/pngwing.com.png";
import BNBSvg from "../assets/images/BNB.6cbc1a8ce3a8072acf3b2cc19d0ac9ab.svg";
import { CiBadgeDollar } from "react-icons/ci";
import { GiCoins } from "react-icons/gi";
import { FaCaretRight, FaMedium, FaFacebook, FaSquareInstagram, FaYoutube } from "react-icons/fa6";
import { IoGameControllerOutline } from "react-icons/io5";
import { MdOutlineHowToVote } from "react-icons/md";
import { PiNewspaperClippingLight } from "react-icons/pi";
import { FaCopy } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { SiCoinmarketcap, SiTiktok } from "react-icons/si";
import WebImage from "../assets/images/dogemob-web.png";
import DogemobGame from "../assets/images/dogemob-game.png";
import TeamM1 from "../assets/images/teamm1.png";
import TeamM2 from "../assets/images/teamm2.png";
import TeamM3 from "../assets/images/teamm3.png";
import TeamM4 from "../assets/images/teamm4.png";
import pcslogo from "../assets/images/pcslogowhite.png";
import PCSWhite from "../assets/images/pcswhite.png";
import GOTBITWhite from "../assets/images/gotbit-1024x211.png";
import DEXTOOLSWhite from "../assets/images/dextoolswhite.png";
import ContractWolf from "../assets/images/contractwolf.png";
import FRESHCOINSWhite from "../assets/images/freshcoinswhite.png";
import LISTINGURU from "../assets/images/listinguru.png";
import BITMARTWhite from "../assets/images/bitmartwhite.png";
import MEXCWhite from "../assets/images/mexcwhite.png";
import GATEIOWhite from "../assets/images/gateiowhite.png";
import BITGETWhite from "../assets/images/bitget-logo.png";
import LBANKWhite from "../assets/images/lbank.png";
import LATOKENWhite from "../assets/images/latoken.png";
import BINGXWhite from "../assets/images/bingxwhite.png";
import CWAudit from "../assets/images/cwaudit.png";
import COINBRAINWhite from "../assets/images/coinbrain.avif";
import TokenSpinAsset from "../assets/images/HIGH.gif";
import Footer from "../components/Footer/Footer";

const Homepage = () => {
  const [coppied, setCoppied] = useState(false);
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText("0xC44F8508E1dE753e7c523F98639132eeF2AD8EA5")
      .then(() => {
        console.log("Address copied to clipboard");
        setCoppied(true);
        setTimeout(() => {
          setCoppied(false);
        }, 1000); // Change back to copyButton after 2 seconds
      })
      .catch((error) => {
        console.error("Failed to copy address to clipboard:", error);
      });
  };

  const [activeTab, setActiveTab] = useState("");
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const arcadeRef = useRef(null);
  const stakeRef = useRef(null);
  const tokenomicsRef = useRef(null);
  const governanceRef = useRef(null);
  const roadmapRef = useRef(null);
  const teamRef = useRef(null);
  const buydogemobRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const refs = [
        homeRef,
        aboutRef,
        arcadeRef,
        stakeRef,
        tokenomicsRef,
        buydogemobRef,
        governanceRef,
        roadmapRef,
        teamRef,
      ];

      refs.forEach((ref, index) => {
        if (ref.current) {
          // Ensure ref is not null
          const { top } = ref.current.getBoundingClientRect();
          const threshold = 560; // Adjust as needed
          if (top <= threshold) {
            setActiveTab(ref.current.id);
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleTabClick = (tab) => {
    let yOffset = -140; // Default offset

    // Change yOffset based on tab
    if (tab === "about") {
      yOffset = -140;
    } else if (tab === "arcade") {
      yOffset = -140;
    } else if (tab === "stake") {
      yOffset = -210;
    } else if (tab === "tokenomics") {
      yOffset = -415;
    } else if (tab === "buydogemob") {
      yOffset = -440;
    } else if (tab === "governance") {
      yOffset = -40;
    } else if (tab === "roadmap") {
      yOffset = -240;
    } else if (tab === "team") {
      yOffset = -40;
    }

    const ref =
      tab === "home"
        ? homeRef
        : tab === "about"
        ? aboutRef
        : tab === "arcade"
        ? arcadeRef
        : tab === "stake"
        ? stakeRef
        : tab === "tokenomics"
        ? tokenomicsRef
        : tab === "buydogemob"
        ? buydogemobRef
        : tab === "governance"
        ? governanceRef
        : tab === "roadmap"
        ? roadmapRef
        : tab === "team"
        ? teamRef
        : "";

    if (ref.current) {
      // Ensure ref is not null
      const y =
        ref.current.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };
  return (
    <>
      <div className="App">
        <div className="noise"></div>
        <Header activeTab={activeTab} handleTabClick={handleTabClick} />
        <div className="imBhgw" id="home" ref={homeRef}>
          <img src={DogemobHeaderImage} className="jDrdFD" />
          <div className="gQPvs iiWBxn">
            <p className="fWCKvA">
              <a
                href="https://contractwolf.io/projects/dogemob"
                target="_blank"
                rel="noreferrer"
                className="kUvDJu"
              >
              <img src={CWAudit} alt="" />
              </a>
              In the realm of memecoins, DOGEMOB sets itself apart through its
              growing ecosystem, utility, and community-driven approach.
              <a
                href="https://t.me/DOGEMOB"
                target="_blank"
                rel="noreferrer"
                class="diRBsa"
              >
                Join Telegram
              </a>
            </p>
            <div className="hVIgPF">
              <a
                className="djSBVF"
                onClick={() => handleTabClick("buydogemob")}
              >
                <div className="dkmaOE">
                  <CiBadgeDollar />
                </div>
                <div className="yrsAb">
                  <div className="cRaBsI">
                    <div className="feWOU">Buy DOGEMOB</div>
                    <div className="lifaGf">View more</div>
                  </div>
                  <FaCaretRight />
                </div>
              </a>
              <a onClick={() => handleTabClick("stake")} className="djSBVF">
                <div className="dkmaOE">
                  <GiCoins />
                </div>
                <div className="yrsAb">
                  <div className="cRaBsI">
                    <div className="feWOU">Stake DOGEMOB</div>
                    <div className="lifaGf">View more</div>
                  </div>
                  <FaCaretRight />
                </div>
              </a>
              <a onClick={() => handleTabClick("arcade")} className="djSBVF">
                <div className="dkmaOE">
                  <IoGameControllerOutline />
                </div>
                <div className="yrsAb">
                  <div className="cRaBsI">
                    <div className="feWOU">DOGEMOB Arcade</div>
                    <div className="lifaGf">View more</div>
                  </div>
                  <FaCaretRight />
                </div>
              </a>
              <div
                className="djSBVF"
                onClick={() => handleTabClick("governance")}
              >
                <div className="dkmaOE">
                  <MdOutlineHowToVote />
                </div>
                <div className="yrsAb">
                  <div className="cRaBsI">
                    <div className="feWOU">Governance</div>
                    <div className="lifaGf">View more</div>
                  </div>
                  <FaCaretRight />
                </div>
              </div>
              <a
                href="/DOGEMOB-Whitepaper.pdf"
                rel="noreferrer"
                target="_blank"
                className="djSBVF"
              >
                <div className="dkmaOE">
                  <PiNewspaperClippingLight />
                </div>
                <div className="yrsAb">
                  <div className="cRaBsI">
                    <div className="feWOU">Whitepaper</div>
                    <div className="lifaGf">View more</div>
                  </div>
                  <FaCaretRight />
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="gQPvs eiCnHU">
          <div className="ghtYsH">
            <h2 className="eJlFOo">
              Smart <br />
              Contract
            </h2>
            <div className="fkCKDU">
              <div className="kMWtxG">
                <img src={BNBSvg} alt="BNB" />
                <p className="kvigvj">
                  0xC44F8508E1dE753e7c523F98639132eeF2AD8EA5
                </p>
              </div>
              <button className="cGafjR" onClick={copyToClipboard}>
                <FaCopy />
              </button>
              <div className="jwFOPZ">
                copy to clipboard
                <svg
                  width="20"
                  height="15"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 15"
                  fill="none"
                >
                  <path
                    opacity="0.3"
                    d="M1 13.5C0.723858 13.5 0.5 13.7239 0.5 14C0.5 14.2761 0.723858 14.5 1 14.5V13.5ZM16.3536 0.646446C16.1583 0.451184 15.8417 0.451184 15.6464 0.646446L12.4645 3.82843C12.2692 4.02369 12.2692 4.34027 12.4645 4.53553C12.6597 4.7308 12.9763 4.7308 13.1716 4.53553L16 1.70711L18.8284 4.53553C19.0237 4.7308 19.3403 4.7308 19.5355 4.53553C19.7308 4.34027 19.7308 4.02369 19.5355 3.82843L16.3536 0.646446ZM1 14.5C5.07513 14.5 8.9437 13.4822 11.8057 11.2706C14.6852 9.04556 16.5 5.6458 16.5 1L15.5 0.999999C15.5 5.3542 13.8148 8.45444 11.1943 10.4794C8.5563 12.5178 4.92487 13.5 1 13.5V14.5Z"
                    fill="white"
                  ></path>
                </svg>
              </div>
              {coppied ? <div className="sc-khsqcC dtJqJL">Copied!</div> : null}
            </div>
          </div>
        </div>
        <div className="gUPXGA" id="about" ref={aboutRef}>
          <img src={WebImage} alt="Web" className="kCUYMN" />
          <div className="bDIlDS bbNTyu">
            <div className="kOGaJo">
              <h2 class="hfIqJo" style={{ textAlign: "right" }}>
                About <br /> DOGEMOB
              </h2>
              <div className="gFFLVz">
                <p class="ggDSuj">
                  Prepare for the ultimate meme-driven crypto experience with
                  DOGEMOB, set to make waves this #ALTSZN! Boasting a fixed
                  total supply of 1 billion coins,{" "}
                  <span>
                    DOGEMOB brings stability to the unpredictable altcoin
                    landscape.
                  </span>{" "}
                  This memecoin stands out by embracing its meme status,
                  positioning itself as a speculative investment for those
                  looking to ride the meme wave during the alt season.
                </p>
                <p class="ggDSuj">
                  Empowered with newfound utility,{" "}
                  <span>
                    DOGEMOB offers investors more than just the thrill of the
                    meme.
                  </span>{" "}
                  It's a community-driven digital asset that not only embodies
                  the spirit of the meme but also serves practical purposes,
                  providing users with benefits and opportunities.
                </p>
              </div>
            </div>
          </div>
          <img src={DogemobThumb} class="bxsnKu" />
          <div class="blur-about"></div>
        </div>
        <div className="iGMHVy" id="arcade" ref={arcadeRef}>
          <div className="gQPvs BjXji">
            <img src={DogemobRambo} className="sc-fWQKxP eXvJLB" width={920} />
            <h2 className="ccvWeK" style={{ textAlign: "left" }}>
              DOGEMOB <br /> Arcade
            </h2>
            <div className="eUHxTz">
              <p className="hkMEWj">
                Play for free or submit a wager to enter the prize pool. The top
                3 leaderboard entrants will receive their share of the prize
                pool drawn every Friday at 07:00 UTC.
              </p>
            </div>
            <div className="kTExUw">
              <div className="jZXXD">
                <div className="bLbXqJ">
                  <a
                    href="https://arcade.dogemob.com/"
                    target="_blank"
                    class="jLDguS"
                  >
                    <span>play</span>
                  </a>
                  <img src={DogemobGame} />
                </div>
              </div>
              <div className="jEqvyF bg-black-t">
                Play our fast-paced 2D shooter for your chance to win the weekly
                prize pool. Play for free or submit a wager to enter the prize
                pool.
              </div>
            </div>
          </div>
        </div>

        <img
          src={TreeLeft}
          className="glspdd no-phone"
          style={{ width: "1280px" }}
        />
        <img src={TreeRight} className="glspded" />
        <div className="bozcar" id="stake" ref={stakeRef}>
          <div className="gQPvs bSREpq">
            <div className="dniiSB">
              <h2 className="ccvWeK" style={{ textAlign: "right" }}>
                Stake <br /> DOGEMOB
              </h2>
              <div className="bFtCoT">
                <p className="jvvSFb">
                  Start earning additional DOGEMOB tokens by staking your
                  holdings!
                </p>
                <p className="jvvSFb">
                  Our user-friendly interface makes it simple and secure to
                  stake your DOGEMOB tokens and begin earning rewards
                  immediately. Click the button below to head to our staking
                  website.
                </p>
                <a
                  href="https://staking.dogemob.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="sc-gswNZR diRBsb"
                >
                  How to stake?
                </a>
              </div>
            </div>
          </div>
          <img src={TokenSpinAsset} width={360} className="dmUbjx"></img>
        </div>
        <div className="jzMUTv">
          <img src={DogemobTokenomics} className="haSfvH" width={922} />
          <div className="blur-toke" />
          <img src={TreeRight} className="glspdd no-mac"></img>
          <div className="gQPvs eHKnaw" id="tokenomics" ref={tokenomicsRef}>
            <div className="gNRRgK">
              <h2 className="gasgWt">Tokenomics</h2>
              <div className="bqSCLj">
                <div className="pnegl">
                  <div className="hPpbma">
                    <div className="cEcqQq">
                      <div>0</div>
                      <span>%</span>
                    </div>
                    <div className="dmyKkk">BUY Fee</div>
                  </div>
                  <div className=" eJLCbn">
                    <span>SYMBOL</span>
                    $DOGEMOB
                  </div>
                </div>
                <div className="pnegl">
                  <div className="hPpbma">
                    <div className="cEcqQq">
                      <div>0</div>
                      <span>%</span>
                    </div>
                    <div className="dmyKkk">SELL Fee</div>
                  </div>
                  <div className="eJLCbn">
                    <span>SUPPLY</span>
                    1,000,000,000
                  </div>
                </div>
                <div className="pnegl">
                  <div className="hPpbma">
                    <div className="cEcqQq">
                      <div>0</div>
                      <span>%</span>
                    </div>
                    <div className="dmyKkk">Transfer Fee</div>
                  </div>
                  <div className="eJLCbn">
                    <span>CHAIN</span>
                    BSC
                  </div>
                </div>
              </div>
              <div className="gasgWr">
                <a
                  href="https://contractwolf.io/projects/dogemob"
                  target="_blank"
                  rel="noreferrer"
                  className="diRBsa"
                >
                  View ContractWolf Overview
                </a>
                <a
                  href="https://github.com/ContractWolf/smart-contract-audits/blob/main/ContractWolf_Audit_DOGEMOB.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="diRBsa"
                >
                  View Full Security Audit
                </a>
              </div>
            </div>
          </div>
        </div>

        <h1 className="ccvWedK dAiEII">BUY DOGEMOB</h1>
        <h4 className="erPLjy">Easy way to buy on DEX and CEX</h4>
        <div id="buydogemob" ref={buydogemobRef} className="gQPvs gNauvd">
          <a
            href="https://pancakeswap.finance/swap?outputCurrency=0xC44F8508E1dE753e7c523F98639132eeF2AD8EA5"
            target="_blank"
            rel="noreferrer"
            className="kUvDJu"
          >
            <img src={PCSWhite} alt="" width={150} />
          </a>
          <a
            href="https://www.bitmart.com/trade/en-US?symbol=DOGEMOB_USDT"
            target="_blank"
            rel="noreferrer"
            className="kUvDJu"
          >
            <img src={BITMARTWhite} alt="" width={150} />
          </a>
          <a
            href="https://www.mexc.com/exchange/DOGEMOB_USDT"
            target="_blank"
            rel="noreferrer"
            className="kUvDJu"
          >
            <img src={MEXCWhite} alt="" width={150} />
          </a>
          <a
            href="https://www.gate.io/trade/DOGEMOB_USDT"
            target="_blank"
            rel="noreferrer"
            className="kUvDJu"
          >
            <img src={GATEIOWhite} alt="" width={150} />
          </a>
          <a
            href="https://www.bitget.com/spot/DOGEMOBUSDT"
            target="_blank"
            rel="noreferrer"
            className="kUvDJu"
          >
            <img src={BITGETWhite} alt="" width={150} />
          </a>
          <a
            href="https://www.lbank.com/trade/dogemob_usdt"
            target="_blank"
            rel="noreferrer"
            className="kUvDJu"
          >
            <img src={LBANKWhite} alt="" width={150} />
          </a>
        <a
          href="https://latoken.com/exchange/DOGEMOB_USDT"
          target="_blank"
          rel="noreferrer"
          className="kUvDJu"
        >
          <img src={LATOKENWhite} alt="" width={150} />
        </a>
        <a
          href="https://bingx.com/en/spot/DOGEMOBUSDT"
          target="_blank"
          rel="noreferrer"
          className="kUvDJu"
        >
          <img src={BINGXWhite} alt="" width={150} />
        </a>
        </div>
        <div className="gQPvs gasgxX">
          <div className="gasTas"></div>
          <iframe
            width="100%"
            height="600"
            frameBorder="0"
            scrolling="no"
            src="https://coinbrain.com/embed/bnb-0xc44f8508e1de753e7c523f98639132eef2ad8ea5?theme=custom&accent=66d1ff&padding=8&background=000000&chart=1&trades=1&simple=1&ratio=0.65"
          ></iframe>
        </div>

        <div id="governance" ref={governanceRef} className="fceYE">
          <div className="gQPvs kiWAbQ">
            <div className="JCZao">
              <img src={DogemobGov} className="no-phone fZIdQF" />
              <div className="fMlEwF">
                <div className="ehWWpn"></div>

                <a
                  href="https://forum.dogemob.com/"
                  target="_blank"
                  className="yaZYU jMUiNT"
                >
                  View Discussions
                </a>
                <div className="sc-UpCWa jLDguP">
                  <span>&</span>
                </div>
                <a
                  href="https://vote.dogemob.com/#/"
                  target="_blank"
                  className="yaZYU jMUiND"
                >
                  Submit Your Vote
                </a>
              </div>
            </div>
            <div className="hvuOkT">
              <div className="faAtLm">
                <h2 className="ccvWeK erQkjj">
                  DOGEMOB <br /> Governance
                </h2>
                <p className="sVIXb">
                  DOGEMOB is where innovation meets community governance! We’re
                  not just another memecoin; we’re a dynamic ecosystem powered
                  by a robust governance system. Our platform features a
                  dedicated discussion board{" "}
                  <span>
                    where $DOGEMOB holders can submit their DogeMob Improvement
                    Proposals (DMIP) and actively participate
                  </span>{" "}
                  in shaping the future of our project.
                </p>

                <p className="sVIXb">
                  With our on-chain voting system, every $DOGEMOB holder has{" "}
                  <span>
                    a voice and a vote in deciding the direction of the project.
                  </span>{" "}
                  Your input matters, and we empower our community to drive
                  meaningful change and innovation. Join us in revolutionizing
                  the memecoin landscape while embracing transparency,
                  inclusivity, and democratic decision-making. Together, we are
                  the force behind DOGEMOB’s success and evolution.
                </p>
              </div>
            </div>
          </div>
        </div>
        <h1 className="ccvWedK dAiEII">Our Partners</h1>
        <div className="gQPvs gNauve">
          <a
            href="https://gotbit.io/"
            target="_blank"
            rel="noreferrer"
            className="kUvDJu"
          >
            <img src={GOTBITWhite} alt="" />
          </a>
          <a
            href="https://contractwolf.io/projects/dogemob"
            target="_blank"
            rel="noreferrer"
            className="kUvDJu"
          >
            <img src={ContractWolf} alt="" />
          </a>
          <a
            href="https://www.dextools.io/app/en/bnb/pair-explorer/0x153056c5c8ff923f06c2945286e00676c85a15b9"
            target="_blank"
            rel="noreferrer"
            className="kUvDJu"
          >
            <img src={DEXTOOLSWhite} alt="" />
          </a>
          <a
            href="https://www.freshcoins.io/coins/dogemob"
            target="_blank"
            rel="noreferrer"
            className="kUvDJu"
          >
            <img src={FRESHCOINSWhite} alt="" />
          </a>
          <a
            href="https://listing-guru.com/"
            target="_blank"
            rel="noreferrer"
            className="kUvDJu"
          >
            <img src={LISTINGURU} alt="" />
          </a>
          <a
            href="https://coinbrain.com/coins/bnb-0xc44f8508e1de753e7c523f98639132eef2ad8ea5"
            target="_blank"
            rel="noreferrer"
            className="kUvDJu"
          >
            <img src={COINBRAINWhite} alt="" />
          </a>
        </div>
        <section className="gbmyFo" id="roadmap" ref={roadmapRef}>
          <div className="eirPYJ"></div>
          <div className="eirPYJ bNKOrk"></div>
          <h1 className="ccvWedK kdaIjJ">ROADMAP</h1>
          <div className="gagKga">
            <div className="gXVxC">
              <div className="bgVemk">
                <h4 className="lbKNTY">Q1 & Q2 - 2024</h4>
                <ul className="fsqGzr">
                  <li className="gdAppR">Launch $DOGEMOB token on pinksale.</li>
                  <li className="gdAppR">Launch $DOGEMOB on pancakeswap.</li>
                  <li className="gdAppR">
                    Launch $DOGEMOB on public exchange.
                  </li>
                  <li className="gdAppR">
                    Social media campaigns, AMAs, and partnerships with meme
                    influencers.
                  </li>
                  <li className="gdAppR">
                    Launch community-driven initiatives to increase awareness
                    and engagement.
                  </li>
                  <li className="gdAppR">
                    Host meme-themed events to create a fun and engaging
                    atmosphere.
                  </li>
                </ul>
              </div>
            </div>
            <div className="gXVxC">
              <div className="bgVemk">
                <h4 className="lbKNTY">Q3 - 2024</h4>
                <ul className="fsqGzr">
                  <li className="gdAppR">
                    Partner with meme-related platforms to expand DOGEMOB’s
                    presence.
                  </li>
                  <li className="gdAppR">
                    Encourage the creation of memes, gifs, and other content
                    featuring DOGEMOB.
                  </li>
                  <li className="gdAppR">
                    Showcase and reward outstanding community-generated content.
                  </li>
                  <li className="gdAppR">
                    Plan social media takeovers with popular meme accounts to
                    reach a broader audience.
                  </li>
                  <li className="gdAppR">
                    Create and share DOGEMOB-related memes consistently on
                    official channels.
                  </li>
                </ul>
              </div>
            </div>
            <div className="gXVxC">
              <div className="bgVemk">
                <h4 className="lbKNTY">Q4 - 2024</h4>
                <ul className="fsqGzr">
                  <li className="gdAppR">
                    Conduct regular community giveaways to reward loyal DOGEMOB
                    supporters.
                  </li>
                  <li className="gdAppR">
                    Explore partnerships with meme-related nft projects.
                  </li>
                  <li className="gdAppR">
                    Release limited edition DOGEMOB-themed nfts to promote
                    community engagement.
                  </li>
                  <li className="gdAppR">
                    Investigate the development of interactive meme platforms or
                    games involving DOGEMOB.
                  </li>
                  <li className="gdAppR">
                    Collaborate with game developers to create meme-based gaming
                    experiences.
                  </li>
                </ul>
              </div>
            </div>
            <div className="gXVxC">
              <div className="bgVemk">
                <h4 className="lbKNTY">ONGOING FOCUS</h4>
                <ul className="fsqGzr">
                  <li className="gdAppR">
                    Regularly analyze meme trends, engagement metrics, and
                    overall meme culture.
                  </li>
                  <li className="gdAppR">
                    Adapt the roadmap based on the evolving meme landscape.
                  </li>
                  <li className="gdAppR">
                    Maintain transparent communication with the community
                    through meme-centric updates.
                  </li>
                  <li className="gdAppR">
                    Address concerns promptly and involve the community in
                    meme-related decision-making processes.
                  </li>
                  <li className="gdAppR">
                    Establish a meme ambassador program to empower enthusiastic
                    community members.
                  </li>
                  <li className="gdAppR">
                    Provide incentives for meme ambassadors to contribute to
                    DOGEMOB’s meme ecosystem.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <div className="iXTXYy" id="community">
          <img src={DogemobHi} className="eEZKoh"></img>
          <div className="gQPvs dDZqqI">
            <div className="enRuIl">
              <img src={CyberPunkAsset1} className="sc-knEsKG cRwEgx" />
              <h2 className="ccvWeKa gDsIaM">
                Hi, I’m <br /> DOGEMOB
              </h2>
              <p className="eZdyRc">
                Step into the heart of our vibrant community, where passion for
                all things crypto and the excitement of DOGEMOB collide!{" "}
                <span>
                  Our community is a dynamic hub of crypto enthusiasts, meme
                  lovers, and adventurous investors who share a common goal – to
                  ride the waves of the alt season with DOGEMOB.
                </span>{" "}
                From seasoned traders to meme aficionados, our diverse members
                bring a kaleidoscope of perspectives, creating an environment
                where knowledge and enthusiasm intertwine.
              </p>

              <p className="eZdyRc">
                Join us in this exciting crypto adventure, where the love for
                DOGEMOB unites us all.{" "}
                <span>
                  Together, we celebrate the meme culture, navigate the twists
                  and turns of the altcoin market, and revel in the potential
                  rewards that come with being part of a community
                </span>{" "}
                that’s as vibrant and dynamic as the crypto world itself.
              </p>
              <div className="iTkrBb">
                <a
                  href="https://t.me/DOGEMOB"
                  target="_blank"
                  rel="noreferrer"
                  className="cyBHaY"
                >
                  <FaTelegramPlane />
                </a>
                <a
                  href="https://twitter.com/DogeMobOfficial"
                  target="_blank"
                  rel="noreferrer"
                  className="cyBHaY"
                >
                  <FaXTwitter />
                </a>
                <a
                  href="https://coinmarketcap.com/currencies/dogemob/"
                  target="_blank"
                  rel="noreferrer"
                  className="cyBHaY"
                >
                  <SiCoinmarketcap />
                </a>

                <a
                  href="https://www.coingecko.com/en/coins/dogemob"
                  target="_blank"
                  rel="noreferrer"
                  className="cyBHaY"
                >
                  <svg
                    width="28"
                    height="29"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 28 29"
                    fill="none"
                  >
                    <path
                      d="M27.6836 14.465C27.701 20.0674 24.3675 25.1287 19.2015 27.2929C14.0354 29.457 8.08402 28.3052 4.10477 24.3608C0.125524 20.4165 -1.07872 14.465 1.03307 9.28154C3.14487 4.09805 8.18874 0.712189 13.7737 0.677283C21.4355 0.65983 27.6487 6.82069 27.6836 14.465Z"
                      fill="white"
                    ></path>
                    <path
                      d="M26.6539 14.465C26.6713 19.6485 23.5822 24.3258 18.8001 26.3329C14.018 28.34 8.52037 27.2579 4.83782 23.6277C1.15527 19.9801 0.0382862 14.4824 1.99301 9.70036C3.94773 4.90083 8.60764 1.77676 13.7737 1.74186C20.8595 1.70695 26.619 7.39658 26.6539 14.465Z"
                      fill="#1B1B1F"
                    ></path>
                    <path
                      d="M20.3359 9.9443C19.4109 9.68251 18.451 9.29855 17.4736 8.91458C17.4213 8.67024 17.1944 8.37354 16.7581 7.98958C16.1298 7.43109 14.9255 7.44854 13.8958 7.69288C12.7614 7.43109 11.6269 7.32637 10.5448 7.58817C1.69625 10.0316 6.70521 15.9655 3.45898 21.9519C3.91276 22.9292 8.90427 28.6538 16.1123 27.1179C16.1123 27.1179 13.6515 21.2014 19.2189 18.3566C23.7392 16.0528 26.9854 11.7594 20.3359 9.9443Z"
                      fill="white"
                    ></path>
                    <path
                      d="M13.8958 7.69318C14.5415 7.74554 16.8802 8.49601 17.4736 8.91488C16.9675 7.4663 15.292 7.27431 13.8958 7.69318Z"
                      fill="white"
                    ></path>
                    <path
                      d="M21.4354 13.8365C21.4354 14.3077 20.8595 14.5521 20.5279 14.2205C20.1963 13.8889 20.4232 13.3129 20.8944 13.2955C21.1911 13.3129 21.4354 13.5398 21.4354 13.8365ZM14.5066 11.3756C14.5066 12.3705 13.8958 13.278 12.9708 13.662C12.0458 14.0459 10.9812 13.8365 10.2656 13.1209C9.55003 12.4054 9.34059 11.3407 9.72456 10.4157C10.1085 9.49074 11.0161 8.87988 12.0109 8.87988C12.6741 8.87988 13.3024 9.14168 13.7562 9.59545C14.2448 10.0841 14.5066 10.7124 14.5066 11.3756Z"
                      fill="white"
                    ></path>
                    <path
                      d="M13.7737 11.3931C13.7737 12.0912 13.3548 12.737 12.6916 12.9988C12.0459 13.2606 11.2954 13.1209 10.7893 12.6148C10.2831 12.1087 10.1435 11.3757 10.4053 10.7124C10.6671 10.0667 11.3128 9.63037 12.011 9.63037C12.9883 9.64782 13.7737 10.4332 13.7737 11.3931Z"
                      fill="#58595B"
                    ></path>
                    <path
                      d="M23.4425 14.9361C21.4354 16.3498 19.1491 17.4144 15.9203 17.4144C14.4019 17.4144 14.1052 15.8087 13.0929 16.5941C12.5693 16.9955 10.7543 17.9031 9.30566 17.8507C7.85708 17.7809 5.51839 16.9257 4.87264 13.854C4.61085 16.9432 4.48868 19.212 3.31934 21.8125C5.62311 25.5125 11.1208 28.3573 16.1123 27.1356C15.5712 23.3833 18.8524 19.7182 20.6849 17.8507C21.4005 17.1177 22.7444 15.9658 23.4425 14.9361Z"
                      fill="white"
                    ></path>
                    <path
                      d="M23.3551 15.0234C22.7268 15.5994 21.9938 16.0182 21.2433 16.3848C20.4754 16.7513 19.69 17.0305 18.8697 17.2574C18.0494 17.4668 17.1942 17.6239 16.3391 17.5541C15.4664 17.4843 14.5938 17.1876 14.0178 16.5418L14.0527 16.5069C14.7508 16.9607 15.5711 17.1178 16.374 17.1527C17.1942 17.1701 18.0145 17.1178 18.8174 16.9607C19.6202 16.7862 20.423 16.5418 21.1909 16.2277C21.9589 15.9135 22.7093 15.547 23.3726 15.0234H23.3551Z"
                      fill="#58595B"
                    ></path>
                  </svg>
                </a>
                <a
                  href="https://www.facebook.com/groups/309587292154179"
                  target="_blank"
                  rel="noreferrer"
                  className="cyBHaY"
                >
                  <FaFacebook />
                </a>
                <a
                  href="https://instagram.com/DogeMobOfficial"
                  target="_blank"
                  rel="noreferrer"
                  className="cyBHaY"
                >
                  <FaSquareInstagram />
                </a>
                <a
                  href="https://youtube.com/@DogeMobOfficial"
                  target="_blank"
                  rel="noreferrer"
                  className="cyBHaY"
                >
                  <FaYoutube/>
                </a>
                <a
                  href="https://tiktok.com/@DogeMobOfficial"
                  target="_blank"
                  rel="noreferrer"
                  className="cyBHaY"
                >
                  <SiTiktok />
                </a>
                <a
                  href="https://medium.com/@DogeMob"
                  target="_blank"
                  rel="noreferrer"
                  className="cyBHaY"
                >
                  <FaMedium />
                </a>
                <a
                  href="https://pancakeswap.finance/swap?outputCurrency=0xC44F8508E1dE753e7c523F98639132eeF2AD8EA5"
                  target="_blank"
                  rel="noreferrer"
                  className="cyBHaY"
                >
                  <img src={pcslogo} width={21} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Homepage;
