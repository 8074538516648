import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Arcade from "./pages/Arcade";
import ScrollToTop from "./utils/scrollToTop";

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/arcade" element={<Arcade />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
