import React, { useState, useEffect } from "react";
import axios from "axios";
import BigValueFormatter from "../../utils/bigNumbers";
import BigValueFormatterVolume from "../../utils/bigNumbersVolume";
import calculatePercentageChange from "../../utils/percentageMove";
import pcslogo from "../../assets/images/pancakeswap-cake-logo.png";
import pcslogow from "../../assets/images/pcslogowhite.png";
import mexclogo from "../../assets/images/mexc-logo-big.png";
import bitmartlogo from "../../assets/images/bitmart.png";
import gatelogo from "../../assets/images/gate.io-logo.png";
import bitgetlogo from "../../assets/images/bitgetlogo.png";
import lbanklogo from "../../assets/images/lbanklogo.png";
import lalogo from "../../assets/images/lalogo.png";
import bingxlogo from "../../assets/images/bingxlogo.png";
import dogemobavatar from "../../assets/images/dogemob-avatar.png";
import { FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { SiCoinmarketcap, SiTiktok } from "react-icons/si";
import { FaMedium, FaFacebook, FaSquareInstagram, FaYoutube } from "react-icons/fa6";
import "animate.css";

const Header = ({ activeTab, handleTabClick }) => {
  const [responseData, setResponseData] = useState(null);
  const [showMarketCap, setShowMarketCap] = useState(true); // Initially show market cap
  const [mobileMenu, setMobileMenu] = useState(false);

  const handleMobileMenu = () => {
    // Use the state updater function to get the updated value of mobileMenu
    setMobileMenu((prevMobileMenu) => {
      const newMobileMenu = !prevMobileMenu;
      if (newMobileMenu) {
        document.body.classList.add("mobile-menu-open");
        document.body.style.overflow = "hidden";
      } else {
        document.body.classList.remove("mobile-menu-open");
        document.body.style.overflow = null;
      }
      return newMobileMenu; // Return the new value for mobileMenu
    });
  };

  // useEffect(() => {
  //   // Set interval to toggle between market cap and volume every minute
  //   const interval = setInterval(() => {
  //     setShowMarketCap((prev) => !prev);
  //   }, 10000); // 60000 milliseconds = 1 minute

  //   // Clean up interval to prevent memory leaks
  //   return () => clearInterval(interval);
  // }, []); // Empty dependency array means this effect runs only once on component mount

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://api.coinbrain.com/public/coin-info",
          {
            56: ["0xC44F8508E1dE753e7c523F98639132eeF2AD8EA5"],
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setResponseData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData(); // Fetch data initially when component mounts

    const intervalId = setInterval(() => {
      fetchData(); // Fetch data every 5 minutes
    }, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Cleanup function to clear the interval when component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []); // Empty dependency array as the dependency to run only once

  useEffect(() => {
    // Check if body has mobile-menu-open class when component mounts
    const hasMobileMenuOpenClass =
      document.body.classList.contains("mobile-menu-open");
    setMobileMenu(hasMobileMenuOpenClass);

    // Add event listener to update state when class changes
    const handleBodyClassChange = () => {
      const hasClass = document.body.classList.contains("mobile-menu-open");
      setMobileMenu(hasClass);
    };

    document.body.addEventListener("transitionend", handleBodyClassChange);

    // Cleanup event listener
    return () => {
      document.body.removeEventListener("transitionend", handleBodyClassChange);
    };
  }, []);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Check if the page is scrolled
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Attach event listener for scroll
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [window.scrollY]); // Empty dependency array means this effect runs only once on mount
  return (
    <>
      <nav className={`${isScrolled || mobileMenu ? "eFYKam" : "cCDhqr"}`}>
        <div className="gETbUR gQPvs">
          <div className="dOjLXf">
            <div className="kDpGQw" onClick={() => handleTabClick("home")}>
              <span className="custom-span animated-vtext">DOGEMOB</span>
            </div>

            <div className="kFYxgS">
              <div
                className={`eKgrXn ${
                  activeTab === "about" ? "active-menu" : ""
                }`}
                onClick={() => handleTabClick("about")}
              >
                About
              </div>
              <div
                className={`eKgrXn ${
                  activeTab === "arcade" ? "active-menu" : ""
                }`}
                onClick={() => handleTabClick("arcade")}
              >
                Arcade
              </div>
              <div
                className={`eKgrXn ${
                  activeTab === "stake" ? "active-menu" : ""
                }`}
                onClick={() => handleTabClick("stake")}
              >
                Stake
              </div>
              <div
                className={`eKgrXn ${
                  activeTab === "tokenomics" && activeTab !== "buydogemob"
                    ? "active-menu"
                    : ""
                }`}
                onClick={() => handleTabClick("tokenomics")}
              >
                Tokenomics
              </div>
              <div
                className={`eKgrXn ${
                  activeTab === "governance" ? "active-menu" : ""
                }`}
                onClick={() => handleTabClick("governance")}
              >
                Governance
              </div>
              <div
                className={`eKgrXn ${
                  activeTab === "roadmap" ? "active-menu" : ""
                }`}
                onClick={() => handleTabClick("roadmap")}
              >
                Roadmap
              </div>
            </div>
          </div>
          <div className="gyekPf">
            <div className="sc-hBxehG FSAUR">
              <div className="jOskAN">
                <img className="sc-dIfARi jWrwUx" src={dogemobavatar} alt="" />
              </div>
              <div className="dIcfzu animate__animated animate__bounceIn animate__delay-1s">
                <div className="ddMbHC">
                  {responseData !== null ? (
                    <div>
                      ${responseData[0]?.priceUsd.toFixed(5)}{" "}
                      <span
                        className={`percent-change ${
                          calculatePercentageChange(
                            responseData[0]?.priceUsd,
                            responseData[0]?.priceUsd24hAgo
                          ) > 0
                            ? "green"
                            : "red"
                        }`}
                      >
                        {calculatePercentageChange(
                          responseData[0]?.priceUsd,
                          responseData[0]?.priceUsd24hAgo
                        ) > 0
                          ? "+"
                          : ""}
                        {calculatePercentageChange(
                          responseData[0]?.priceUsd,
                          responseData[0]?.priceUsd24hAgo
                        )}
                        %
                      </span>
                    </div>
                  ) : (
                    "loading"
                  )}
                </div>
                <>
                  <div className="gynApa">
                    {showMarketCap ? (
                      responseData !== null ? (
                        <BigValueFormatter
                          value={responseData[0]?.marketCapUsd}
                        />
                      ) : (
                        "loading"
                      )
                    ) : responseData !== null ? (
                      <BigValueFormatterVolume
                        value={responseData[0]?.volume24hUsd}
                      />
                    ) : (
                      "loading"
                    )}
                  </div>
                </>
              </div>
            </div>
            <div
              id="buydogemob"
              className="diRBsc"
              onClick={() => handleTabClick("buydogemob")}
            >
              Buy now
            </div>
            <div
              className={`${mobileMenu ? "fcAAV" : "eHMfMO"}`}
              onClick={handleMobileMenu}
            >
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </nav>
      <div className={`${mobileMenu ? "XuMrk" : "esDaVp"}`}>
        <div className="itetuu">
          <div className="sc-hBxehG FSAUR">
            <div className="jOskAN">
              <img className="sc-dIfARi jWrwUx" src={dogemobavatar} alt="" />
            </div>
            <div className="dIcfzu">
              <div className="ddMbHC animate__animated animate__flash">
                {responseData !== null ? (
                  <div>
                    ${responseData[0]?.priceUsd.toFixed(5)}{" "}
                    <span
                      className={`percent-change ${
                        calculatePercentageChange(
                          responseData[0]?.priceUsd,
                          responseData[0]?.priceUsd24hAgo
                        ) > 0
                          ? "green"
                          : "red"
                      }`}
                    >
                      {calculatePercentageChange(
                        responseData[0]?.priceUsd,
                        responseData[0]?.priceUsd24hAgo
                      ) > 0
                        ? "+"
                        : ""}
                      {calculatePercentageChange(
                        responseData[0]?.priceUsd,
                        responseData[0]?.priceUsd24hAgo
                      )}
                      %
                    </span>
                  </div>
                ) : (
                  "loading"
                )}
              </div>
              <>
                <div className="gynApa">
                  {showMarketCap ? (
                    responseData !== null ? (
                      <BigValueFormatter
                        value={responseData[0]?.marketCapUsd}
                      />
                    ) : (
                      "loading"
                    )
                  ) : responseData !== null ? (
                    <BigValueFormatterVolume
                      value={responseData[0]?.volume24hUsd}
                    />
                  ) : (
                    "loading"
                  )}
                </div>
              </>
            </div>
          </div>
          <span class="bVfGaI">Buy DOGEMOB</span>
          <a
            class="sc-fEXmlR bVZDti"
            href="https://pancakeswap.finance/swap?outputCurrency=0xC44F8508E1dE753e7c523F98639132eeF2AD8EA5"
            target="_blank"
          >
            <div class="sc-bjfHbI bIcdpu">
              <img src={pcslogo} alt="" width={36} />
            </div>
            Buy from PancakeSwap
          </a>
          <a
            class="sc-fEXmlR bVZDti"
            href="https://www.mexc.com/exchange/DOGEMOB_USDT"
            target="_blank"
          >
            <div class="sc-bjfHbI bIcdpu">
              <img src={mexclogo} alt="" width={36} />
            </div>
            Buy from MEXC
          </a>
          <a
            class="sc-fEXmlR bVZDti"
            href="https://www.bitmart.com/trade/en-US?symbol=DOGEMOB_USDT"
            target="_blank"
          >
            <div class="sc-bjfHbI bIcdpu">
              <img src={bitmartlogo} alt="" width={36} />
            </div>
            Buy from BitMart
          </a>
          <a
            class="sc-fEXmlR bVZDti"
            href="https://www.gate.io/trade/DOGEMOB_USDT"
            target="_blank"
          >
            <div class="sc-bjfHbI bIcdpu">
              <img src={gatelogo} alt="" width={36} />
            </div>
            Buy from Gate.io
          </a>
          <a
            class="sc-fEXmlR bVZDti"
            href="https://www.bitget.com/spot/DOGEMOBUSDT"
            target="_blank"
          >
            <div class="sc-bjfHbI bIcdpu">
              <img src={bitgetlogo} alt="" width={36} />
            </div>
            Buy from Bitget
          </a>
          <a
            class="sc-fEXmlR bVZDti"
            href="https://www.lbank.com/trade/dogemob_usdt"
            target="_blank"
          >
            <div class="sc-bjfHbI bIcdpu">
              <img src={lbanklogo} alt="" width={36} />
            </div>
            Buy from LBank
          </a>
          <a
            class="sc-fEXmlR bVZDti"
            href="https://latoken.com/exchange/DOGEMOB_USDT"
            target="_blank"
          >
            <div class="sc-bjfHbI bIcdpu">
              <img src={lalogo} alt="" width={36} />
            </div>
            Buy from LAToken
          </a>
          <a
            class="sc-fEXmlR bVZDti"
            href="https://bingx.com/en/spot/DOGEMOBUSDT"
            target="_blank"
          >
            <div class="sc-bjfHbI bIcdpu">
              <img src={bingxlogo} alt="" width={36} />
            </div>
            Buy from BingX
          </a>
          <a class="eKgrXn" href="#about" onClick={handleMobileMenu}>
            About
          </a>
          <a class="eKgrXn" href="#arcade" onClick={handleMobileMenu}>
            Arcade
          </a>
          <a class="eKgrXn" href="#stake" onClick={handleMobileMenu}>
            Stake
          </a>
          <a class="eKgrXn" href="#tokenomics" onClick={handleMobileMenu}>
            Tokenomics
          </a>
          <a class="eKgrXn" href="#governance" onClick={handleMobileMenu}>
            Governance
          </a>
          <a class="eKgrXn" href="#roadmap" onClick={handleMobileMenu}>
            Roadmap
          </a>
          <a class="eKgrXn" href="#community" onClick={handleMobileMenu}>
            Community
          </a>

          <div className="iTkrBZ">
            <a
              href="https://t.me/DOGEMOB"
              target="_blank"
              rel="noreferrer"
              className="cyBHaZ"
            >
              <FaTelegramPlane />
            </a>
            <a
              href="https://twitter.com/DogeMobOfficial"
              target="_blank"
              rel="noreferrer"
              className="cyBHaZ"
            >
              <FaXTwitter />
            </a>
            <a
              href="https://coinmarketcap.com/currencies/dogemob/"
              target="_blank"
              rel="noreferrer"
              className="cyBHaZ"
            >
              <SiCoinmarketcap />
            </a>

            <a
              href="https://www.coingecko.com/en/coins/dogemob"
              target="_blank"
              rel="noreferrer"
              className="cyBHaZ"
            >
              <svg
                width="28"
                height="29"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 28 29"
                fill="none"
              >
                <path
                  d="M27.6836 14.465C27.701 20.0674 24.3675 25.1287 19.2015 27.2929C14.0354 29.457 8.08402 28.3052 4.10477 24.3608C0.125524 20.4165 -1.07872 14.465 1.03307 9.28154C3.14487 4.09805 8.18874 0.712189 13.7737 0.677283C21.4355 0.65983 27.6487 6.82069 27.6836 14.465Z"
                  fill="white"
                ></path>
                <path
                  d="M26.6539 14.465C26.6713 19.6485 23.5822 24.3258 18.8001 26.3329C14.018 28.34 8.52037 27.2579 4.83782 23.6277C1.15527 19.9801 0.0382862 14.4824 1.99301 9.70036C3.94773 4.90083 8.60764 1.77676 13.7737 1.74186C20.8595 1.70695 26.619 7.39658 26.6539 14.465Z"
                  fill="#1B1B1F"
                ></path>
                <path
                  d="M20.3359 9.9443C19.4109 9.68251 18.451 9.29855 17.4736 8.91458C17.4213 8.67024 17.1944 8.37354 16.7581 7.98958C16.1298 7.43109 14.9255 7.44854 13.8958 7.69288C12.7614 7.43109 11.6269 7.32637 10.5448 7.58817C1.69625 10.0316 6.70521 15.9655 3.45898 21.9519C3.91276 22.9292 8.90427 28.6538 16.1123 27.1179C16.1123 27.1179 13.6515 21.2014 19.2189 18.3566C23.7392 16.0528 26.9854 11.7594 20.3359 9.9443Z"
                  fill="white"
                ></path>
                <path
                  d="M13.8958 7.69318C14.5415 7.74554 16.8802 8.49601 17.4736 8.91488C16.9675 7.4663 15.292 7.27431 13.8958 7.69318Z"
                  fill="white"
                ></path>
                <path
                  d="M21.4354 13.8365C21.4354 14.3077 20.8595 14.5521 20.5279 14.2205C20.1963 13.8889 20.4232 13.3129 20.8944 13.2955C21.1911 13.3129 21.4354 13.5398 21.4354 13.8365ZM14.5066 11.3756C14.5066 12.3705 13.8958 13.278 12.9708 13.662C12.0458 14.0459 10.9812 13.8365 10.2656 13.1209C9.55003 12.4054 9.34059 11.3407 9.72456 10.4157C10.1085 9.49074 11.0161 8.87988 12.0109 8.87988C12.6741 8.87988 13.3024 9.14168 13.7562 9.59545C14.2448 10.0841 14.5066 10.7124 14.5066 11.3756Z"
                  fill="white"
                ></path>
                <path
                  d="M13.7737 11.3931C13.7737 12.0912 13.3548 12.737 12.6916 12.9988C12.0459 13.2606 11.2954 13.1209 10.7893 12.6148C10.2831 12.1087 10.1435 11.3757 10.4053 10.7124C10.6671 10.0667 11.3128 9.63037 12.011 9.63037C12.9883 9.64782 13.7737 10.4332 13.7737 11.3931Z"
                  fill="#58595B"
                ></path>
                <path
                  d="M23.4425 14.9361C21.4354 16.3498 19.1491 17.4144 15.9203 17.4144C14.4019 17.4144 14.1052 15.8087 13.0929 16.5941C12.5693 16.9955 10.7543 17.9031 9.30566 17.8507C7.85708 17.7809 5.51839 16.9257 4.87264 13.854C4.61085 16.9432 4.48868 19.212 3.31934 21.8125C5.62311 25.5125 11.1208 28.3573 16.1123 27.1356C15.5712 23.3833 18.8524 19.7182 20.6849 17.8507C21.4005 17.1177 22.7444 15.9658 23.4425 14.9361Z"
                  fill="white"
                ></path>
                <path
                  d="M23.3551 15.0234C22.7268 15.5994 21.9938 16.0182 21.2433 16.3848C20.4754 16.7513 19.69 17.0305 18.8697 17.2574C18.0494 17.4668 17.1942 17.6239 16.3391 17.5541C15.4664 17.4843 14.5938 17.1876 14.0178 16.5418L14.0527 16.5069C14.7508 16.9607 15.5711 17.1178 16.374 17.1527C17.1942 17.1701 18.0145 17.1178 18.8174 16.9607C19.6202 16.7862 20.423 16.5418 21.1909 16.2277C21.9589 15.9135 22.7093 15.547 23.3726 15.0234H23.3551Z"
                  fill="#58595B"
                ></path>
              </svg>
            </a>
            <a
              href="https://www.facebook.com/groups/309587292154179"
              target="_blank"
              rel="noreferrer"
              className="cyBHaZ"
            >
              <FaFacebook />
            </a>
            <a
              href="https://instagram.com/DogeMobOfficial"
              target="_blank"
              rel="noreferrer"
              className="cyBHaY"
            >
              <FaSquareInstagram />
            </a>
            <a
              href="https://youtube.com/@DogeMobOfficial"
              target="_blank"
              rel="noreferrer"
              className="cyBHaY"
            >
              <FaYoutube />
            </a>
            <a
              href="https://tiktok.com/@DogeMobOfficial"
              target="_blank"
              rel="noreferrer"
              className="cyBHaY"
            >
              <SiTiktok />
            </a>
            <a
              href="https://medium.com/@DogeMob"
              target="_blank"
              rel="noreferrer"
              className="cyBHaZ"
            >
              <FaMedium />
            </a>
            <a
              href="https://pancakeswap.finance/swap?outputCurrency=0xC44F8508E1dE753e7c523F98639132eeF2AD8EA5"
              target="_blank"
              rel="noreferrer"
              className="cyBHaZ"
            >
              <img src={pcslogow} width={21} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
