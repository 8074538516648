import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header/HeaderSecond";
import DOGEMOBLogo from "../assets/images/DOGEMOB.png";
import { FaFacebook } from "react-icons/fa";
import { FaMedium } from "react-icons/fa6";

const Arcade = () => {
  const [activeTab, setActiveTab] = useState("");
  const homeRef = useRef(null);
  const arcadeRef = useRef(null);
  const stakeRef = useRef(null);
  const tokenomicsRef = useRef(null);
  const governanceRef = useRef(null);
  const roadmapRef = useRef(null);
  const teamRef = useRef(null);
  const buydogemobRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const refs = [
        homeRef,
        arcadeRef,
        stakeRef,
        tokenomicsRef,
        buydogemobRef,
        governanceRef,
        roadmapRef,
        teamRef,
      ];

      refs.forEach((ref, index) => {
        if (ref.current) {
          // Ensure ref is not null
          const { top } = ref.current.getBoundingClientRect();
          const threshold = 560; // Adjust as needed
          if (top <= threshold) {
            setActiveTab(ref.current.id);
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleTabClick = (tab) => {
    let yOffset = -140; // Default offset

    // Change yOffset based on tab
    if (tab === "arcade") {
      yOffset = 60;
    } else if (tab === "stake") {
      yOffset = -210;
    } else if (tab === "tokenomics") {
      yOffset = -415;
    } else if (tab === "buydogemob") {
      yOffset = -440;
    } else if (tab === "governance") {
      yOffset = -40;
    } else if (tab === "roadmap") {
      yOffset = -240;
    } else if (tab === "team") {
      yOffset = -40;
    }

    const ref =
      tab === "home"
        ? homeRef
        : tab === "arcade"
        ? arcadeRef
        : tab === "stake"
        ? stakeRef
        : tab === "tokenomics"
        ? tokenomicsRef
        : tab === "buydogemob"
        ? buydogemobRef
        : tab === "governance"
        ? governanceRef
        : tab === "roadmap"
        ? roadmapRef
        : tab === "team"
        ? teamRef
        : "";

    if (ref.current) {
      // Ensure ref is not null
      const y =
        ref.current.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };
  return (
    <>
      <div className="App" style={{ height: "calc(100vh - 180px)" }}>
        <div className="noise"></div>
        <Header activeTab={activeTab} handleTabClick={handleTabClick} />
        <div class="AAysg">
          <div class="hEgjjJ">
            <div className="gQPvs gasgxY">
              <iframe
                width="100%"
                height="650"
                frameBorder="0"
                scrolling="no"
                src="https://arcade.dogemob.com/"
              ></iframe>
            </div>
          </div>
          <div className="no-mac gfasgS">NO MOBILE SUPPORT</div>
        </div>
      </div>

      <div className="gQPvs kemPbI">
        <div className="dwIKcI">
          <img src={DOGEMOBLogo} alt="DOGEMOB" />
        </div>
        <div className="btUIXF">
          <div className="bbXSHf">
            <a
              href="https://www.facebook.com/groups/309587292154179"
              target="_blank"
              rel="noreferrer"
              className="sc-kMjNwy dUVUMV"
            >
              <FaFacebook style={{ fontSize: 24 }} />
            </a>
            <a
              href="https://medium.com/@DogeMob"
              target="_blank"
              rel="noreferrer"
              className="sc-kMjNwy dUVUMV"
            >
              <FaMedium style={{ fontSize: 24 }} />
            </a>
          </div>
          <a href="https://t.me/DOGEMOB" target="_blank" className="diRBsb">
            Join Telegram
          </a>
        </div>
      </div>
      <div className="gQPvs SlXwf">
        <div className="idBXai">
          <span className="idBXap">Copyright 2024</span>
          <span className="bXVdZp">
            Made with
            <img
              draggable="false"
              role="img"
              width={14}
              height={14}
              className="emoji"
              alt="❤"
              style={{ marginLeft: 4, marginRight: 4 }}
              src="https://s.w.org/images/core/emoji/14.0.0/svg/2764.svg"
            />
            in Sydney, Australia | Phantom Labs Pty Ltd
          </span>
        </div>
      </div>
    </>
  );
};

export default Arcade;
