import React from "react";
import DOGEMOBLogo from "../../assets/images/DOGEMOB.png";

const Footer = () => {
  return (
    <>
      <div className="gQPvs kemPbI">
        <div className="dwIKcI">
          <img src={DOGEMOBLogo} alt="DOGEMOB" />
        </div>
        <div className="btUIXF">
          <a href="https://t.me/DOGEMOB" target="_blank" className="diRBsb">
            Join Telegram
          </a>
        </div>
      </div>
      <div className="gQPvs SlXwf">
        <div className="idBXai">
          <span className="idBXap">Copyright 2024</span>
          <span className="bXVdZp">
            Made with
            <img
              draggable="false"
              role="img"
              width={14}
              height={14}
              className="emoji"
              alt="❤"
              style={{ marginLeft: 4, marginRight: 4 }}
              src="https://s.w.org/images/core/emoji/14.0.0/svg/2764.svg"
            />
            in Sydney, Australia | Phantom Labs Pty Ltd
          </span>
        </div>
      </div>
    </>
  );
};

export default Footer;
