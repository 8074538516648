import React, { useState, useEffect } from "react";
import axios from "axios";
import BigValueFormatter from "../../utils/bigNumbers";
import calculatePercentageChange from "../../utils/percentageMove";
import pcslogo from "../../assets/images/pancakeswap-cake-logo.png";
import mexclogo from "../../assets/images/mexc-logo-big.png";
import bitmartlogo from "../../assets/images/bitmart.png";
import gatelogo from "../../assets/images/gate.io-logo.png";
import dogemobavatar from "../../assets/images/dogemob-avatar.png";
import { Link } from "react-router-dom";

const Header = ({ activeTab, handleTabClick }) => {
  const [responseData, setResponseData] = useState(null);
  const [mobileMenu, setMobileMenu] = useState(false);

  const handleMobileMenu = () => {
    // Use the state updater function to get the updated value of mobileMenu
    setMobileMenu((prevMobileMenu) => {
      const newMobileMenu = !prevMobileMenu;
      if (newMobileMenu) {
        document.body.classList.add("mobile-menu-open");
        document.body.style.overflow = "hidden";
      } else {
        document.body.classList.remove("mobile-menu-open");
        document.body.style.overflow = null;
      }
      return newMobileMenu; // Return the new value for mobileMenu
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://api.coinbrain.com/public/coin-info",
          {
            56: ["0xC44F8508E1dE753e7c523F98639132eeF2AD8EA5"],
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setResponseData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Fetch data initially when component mounts

    const intervalId = setInterval(() => {
      fetchData(); // Fetch data every 5 minutes
    }, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Cleanup function to clear the interval when component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []); // Empty dependency array as the dependency to run only once

  useEffect(() => {
    // Check if body has mobile-menu-open class when component mounts
    const hasMobileMenuOpenClass =
      document.body.classList.contains("mobile-menu-open");
    setMobileMenu(hasMobileMenuOpenClass);

    // Add event listener to update state when class changes
    const handleBodyClassChange = () => {
      const hasClass = document.body.classList.contains("mobile-menu-open");
      setMobileMenu(hasClass);
    };

    document.body.addEventListener("transitionend", handleBodyClassChange);

    // Cleanup event listener
    return () => {
      document.body.removeEventListener("transitionend", handleBodyClassChange);
    };
  }, []);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Check if the page is scrolled
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Attach event listener for scroll
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [window.scrollY]); // Empty dependency array means this effect runs only once on mount
  return (
    <>
      <nav className={`${isScrolled || mobileMenu ? "eFYKam" : "cCDhqr"}`}>
        <div className="gETbUR gQPvs">
          <div className="dOjLXf">
            <Link className="kDpGQw" to="/">
              <span className="custom-span">DOGEMOB</span>
            </Link>

            <div className="kFYxgS">
              <Link
                to="/"
                className={`lhLZgp ${
                  activeTab === "arcade" ? "active-menu" : ""
                }`}
              >
                <div className="cHFckh">Arcade</div>
              </Link>
              <Link
                className={`eKgrXn ${
                  activeTab === "stake" ? "active-menu" : ""
                }`}
                to="/"
              >
                Stake
              </Link>
              <Link
                className={`eKgrXn ${
                  activeTab === "tokenomics" && activeTab !== "buydogemob"
                    ? "active-menu"
                    : ""
                }`}
                to="/"
              >
                Tokenomics
              </Link>
              <Link
                className={`eKgrXn ${
                  activeTab === "governance" ? "active-menu" : ""
                }`}
                to="/"
              >
                Governance
              </Link>
              <Link
                className={`eKgrXn ${
                  activeTab === "roadmap" ? "active-menu" : ""
                }`}
                to="/"
              >
                Roadmap
              </Link>
            </div>
          </div>
          <div className="gyekPf">
            <div className="sc-hBxehG FSAUR">
              <div className="jOskAN">
                <img className="sc-dIfARi jWrwUx" src={dogemobavatar} alt="" />
              </div>
              <div className="dIcfzu">
                <div className="ddMbHC">
                  {responseData !== null ? (
                    <div>
                      ${responseData[0]?.priceUsd.toFixed(5)}{" "}
                      <span
                        className={`percent-change ${
                          calculatePercentageChange(
                            responseData[0]?.priceUsd,
                            responseData[0]?.priceUsd24hAgo
                          ) > 0
                            ? "green"
                            : "red"
                        }`}
                      >
                        {calculatePercentageChange(
                          responseData[0]?.priceUsd,
                          responseData[0]?.priceUsd24hAgo
                        ) > 0
                          ? "+"
                          : ""}
                        {calculatePercentageChange(
                          responseData[0]?.priceUsd,
                          responseData[0]?.priceUsd24hAgo
                        )}
                        %
                      </span>
                    </div>
                  ) : (
                    "loading"
                  )}
                </div>
                <div className="gynApa">
                  {responseData !== null ? (
                    <BigValueFormatter value={responseData[0]?.marketCapUsd} />
                  ) : (
                    "loading"
                  )}
                </div>
              </div>
            </div>
            <Link id="buydogemob" className="diRBsb" to="/">
              Buy DOGEMOB
            </Link>
            <div
              className={`${mobileMenu ? "fcAAV" : "eHMfMO"}`}
              onClick={handleMobileMenu}
            >
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </nav>
      <div className={`${mobileMenu ? "XuMrk" : "esDaVp"}`}>
        <div className="itetuu">
          <span class="bVfGaI">Buy DOGEMOB</span>
          <a
            class="sc-fEXmlR bVZDti"
            href="https://pancakeswap.finance/swap?outputCurrency=0xC44F8508E1dE753e7c523F98639132eeF2AD8EA5"
          >
            <div class="sc-bjfHbI bIcdpu">
              <img src={pcslogo} alt="" width={36} />
            </div>
            Buy from PancakeSwap
          </a>
          <a
            class="sc-fEXmlR bVZDti"
            href="https://www.mexc.com/exchange/DOGEMOB_USDT"
          >
            <div class="sc-bjfHbI bIcdpu">
              <img src={mexclogo} alt="" width={36} />
            </div>
            Buy from MEXC
          </a>
          <a
            class="sc-fEXmlR bVZDti"
            href="https://www.bitmart.com/trade/en-US?symbol=DOGEMOB_USDT"
          >
            <div class="sc-bjfHbI bIcdpu">
              <img src={bitmartlogo} alt="" width={36} />
            </div>
            Buy from BitMart
          </a>
          <a class="sc-fEXmlR bVZDti" href="/">
            <div class="sc-bjfHbI bIcdpu">
              <img src={gatelogo} alt="" width={36} />
            </div>
            Buy from Gate.io
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/freshcoins/Smart-Contract-Audits/blob/main/DOGEMOB_0xC44F8508E1dE753e7c523F98639132eeF2AD8EA5.pdf"
            class="sc-ipEyDJ bVfGaI"
          >
            Audit
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://pinksale.notion.site/DOGEMOB-KYC-Verification-25622368d94842dbb735ea61634ccda5"
            class="sc-ipEyDJ bVfGaI"
          >
            KYC
          </a>
          <a class="sc-csuSiG eKgrXn" href="/" onClick={handleMobileMenu}>
            Arcade
          </a>
          <a class="sc-csuSiG eKgrXn" href="/" onClick={handleMobileMenu}>
            Stake
          </a>
          <a class="sc-csuSiG eKgrXn" href="/" onClick={handleMobileMenu}>
            Tokenomics
          </a>
          <a class="sc-csuSiG eKgrXn" href="/" onClick={handleMobileMenu}>
            Governance
          </a>
          <a class="sc-csuSiG eKgrXn" href="/" onClick={handleMobileMenu}>
            Road map
          </a>
          <div className="sc-hBxehG FSAUR">
            <div className="jOskAN">
              <img className="sc-dIfARi jWrwUx" src={dogemobavatar} alt="" />
            </div>
            <div className="dIcfzu">
              <div className="ddMbHC">
                {responseData !== null ? (
                  <div>
                    ${responseData[0]?.priceUsd.toFixed(5)}{" "}
                    <span
                      className={`percent-change ${
                        calculatePercentageChange(
                          responseData[0]?.priceUsd,
                          responseData[0]?.priceUsd24hAgo
                        ) > 0
                          ? "green"
                          : "red"
                      }`}
                    >
                      {calculatePercentageChange(
                        responseData[0]?.priceUsd,
                        responseData[0]?.priceUsd24hAgo
                      ) > 0
                        ? "+"
                        : ""}
                      {calculatePercentageChange(
                        responseData[0]?.priceUsd,
                        responseData[0]?.priceUsd24hAgo
                      )}
                      %
                    </span>
                  </div>
                ) : (
                  "loading"
                )}
              </div>
              <div className="gynApa">
                {responseData !== null ? (
                  <BigValueFormatter value={responseData[0]?.marketCapUsd} />
                ) : (
                  "loading"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
